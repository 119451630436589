export function IsInLocalStorage(
  key: string,
  propName: string,
  value: string,
  type: any
) {
  try {
    const localStorageObject = localStorage.getItem(key);
    if (localStorageObject && localStorageObject !== '[]') {
      var storageObject = JSON.parse(localStorageObject!) as typeof type;
      let objectComparator = Array.isArray(storageObject)
        ? storageObject[0]
        : storageObject;
      if (propName in objectComparator) {
        return objectComparator[propName] === value;
      }
    }
  } catch (err) {
    console.log(err);
  }
  return false;
}

export function GetFromLocalStorage(key: string, type: any) {
  const localStorageObject = localStorage.getItem(key);
  return JSON.parse(localStorageObject!) as typeof type;
}
