
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { CustomerService } from '@/services/customer-service';
import { Address } from '@/store/models/AddressModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ItemState } from '@/store/models/ItemModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { ConstValues } from '../../ConstValues';
@Component
export default class extends Vue {
  /* Properties */
  @Prop() originalAddress!: Address;
  /* Store Actions */
  /* Watchers */
  @Watch('addressToEdit.Country.Id')
  onCountryIdChange() {
    if (
      this.addressToEdit.Country.Id > ConstValues.DEFAULT_COUNTRY_ID &&
      this.addressToEdit.State != ''
    ) {
      this.addressToEdit.State = '';
    } else {
      this.addressToEdit.Province = '';
    }
  }
  @Watch('addressToEdit', { deep: true })
  onAddressToEditChange(val: any, oldVal: any) {
    if (
      oldVal.Status == ItemState.New ||
      (oldVal.Status === ItemState.Edit && oldVal.Id && this.hasLoadedAddress)
    ) {
      this.addressToEdit.HasChanged = true;
    }
  }
  /* Data */
  hasLoadedAddress: boolean = false;
  // isSavingBillingAddress: boolean = false;
  addressToEdit: Address = new Address();
  shouldOpenDialog: boolean = false;
  isFormValid: boolean = false;
  isSavingAddress: boolean = false;
  states: { id: string; name: string }[] = [];
  countries: any[] = [];
  /* Utility Functions */
  async openAddressDialog(shouldClearData: boolean = false) {
    await this.GetStates();
    await this.GetCountries();
    setTimeout(() => {
      if (shouldClearData || !this.originalAddress) {
        this.addressToEdit = new Address();
        this.addressToEdit.Country = this.countries[0];
      } else {
        Object.assign(this.addressToEdit, this.originalAddress);
        this.hasLoadedAddress = true;
      }
      this.shouldOpenDialog = true;
    }, 50);
  }

  async updateAddress() {
    this.isSavingAddress = true;
    let model = ConversionHelper.convertAddressToAddressModel(
      this.addressToEdit,
      this.originalAddress && this.originalAddress.Id ? false : true,
      true
    );

    if (this.originalAddress && this.originalAddress.Id) {
      await CustomerService.UpdateAddress(model);
      SetSnackBar('Address updated successfully');
      this.$emit('addressUpdated');
    } else {
      const response = await CustomerService.AddNewBillingAddress(model);
      SetSnackBar('Address added successfully');
      this.$emit('addressUpdated', response.data);
    }
    this.isSavingAddress = false;
    this.shouldOpenDialog = false;
  }
  /* Loaders */
  async GetStates() {
    const response = await CustomerService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async GetCountries() {
    const response = await CustomerService.GetCountries();
    this.countries = response.data;
  }
  /* Computed */
  get ConstValues() {
    return ConstValues;
  }
  /* Mounted */
  /* Created */
}
