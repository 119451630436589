export const ColorHelpers = {
  getPacklistStatus(status: string) {
    switch (status) {
      case 'Delivered':
        return 'j1l-black';
      case 'In Transit':
        return 'j1l-brown';
      case 'Pending':
        return 'hot-pink';

      default:
        return 'j1lGreen';
    }
  },
  getInvoiceStatus(status: string) {
    switch (status) {
      case 'Paid':
        return 'j1lGreen';
      case 'Unpaid':
        return 'j1lOrange';
      case 'Past Due':
        return 'hot-pink';

      default:
        return 'j1lGreen';
    }
  }
};
