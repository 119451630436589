export const MathHelper = {
  calculateNumberOfFrames(quantites: number, numberup: number) {
    return Math.ceil(quantites / numberup);
  },
  Truncate(num: number, decimals: number) {
    var re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimals || -1) + '})?');
    return parseFloat(num.toString().match(re)[0]);
  },
  Round(num: number, decimals: number) {
    return parseFloat(num.toFixed(decimals));
  }
};
