
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { QuickDialog } from '../store/models/DialogModel';
import DialogLayout from '@/components/DialogLayout.vue';
import { Orderline, OrderlineDetails, RushOption } from '@/store/models/OrderlineModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { OrdersService } from '@/services/orders-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  /* Properties */
  @Prop({ required: true })
  orderline!: OrderlineDetails;
  /* Store Actions */
  /* Watchers */
  /* Data */
  dialog: boolean = false;
  hasTrackingNumber: boolean = true;
  isGettingTrackingNumber: boolean = false;
  dialogLayoutData: QuickDialog = new QuickDialog();
  trackingNumber: any = null;
  /* Utility Functions */
  async showDialog() {
    this.dialog = true;
    this.GetTrackingInformation();
    if (this.orderline) {
      this.dialogLayoutData = ConversionHelper.convertOrderlineToDialogLayout(
        this.orderline
      );
    }
  }
  async GetTrackingInformation() {
    this.isGettingTrackingNumber = true;
    try {
      const response = await OrdersService.GetTrackingInformation(this.orderline.Id);
      this.trackingNumber = response.data;
    } catch (err) {
      SetSnackBar('Could not grab your tracking information');
    }
    this.isGettingTrackingNumber = false;
  }
  redirectToTrackingUrl() {
    window.open(this.trackingNumber.ShippingCarrier.TrackingUrl);
  }
  /* Loaders */
  /* Mounted */
  /* Created */
}
